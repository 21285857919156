module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://wiki.netlify.hyamstudios.com"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"head":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#0000FF"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-identity-widget/gatsby-browser'),
      options: {"plugins":[],"container":"#netlify-modal"},
    }]
